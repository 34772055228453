import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';
import localStore from 'store';
import { useNavigate, useParams } from 'react-router-dom';

import Dashboard from 'ecto-common/lib/Dashboard/Dashboard';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext, {
  useDashboardDataFromRedux
} from 'ecto-common/lib/hooks/DashboardDataContext';
import { DASHBOARD_LOCAL_STORAGE_KEY } from 'ecto-common/lib/utils/persistentNodeState';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import useTimeRangeSelector from 'ecto-common/lib/Dashboard/context/useTimeRangeSelector';
import { applyDashboardMigrationsToCollection } from 'ecto-common/lib/Dashboard/migrations/migrations';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';

import DashboardCopyPanelToPersonalModal from 'js/components/DashboardPage/DashboardCopyPanelToPersonalModal';
import useDashboardMenu from 'js/components/DashboardPage/useDashboardMenu';
import styles from './DashboardPage.module.css';
import {
  getCachedDashboardCollection,
  setCachedDashboardCollection
} from 'ecto-common/lib/utils/dashboardCollectionCache';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import APIGen, {
  DashboardCollectionViewResponseModel,
  DashboardViewResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { DashboardFile } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import { ProblemDetails } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

// Store which dashboard was last showing for a given collection to make navigation easier
const _updateLastDashboardIndex = (dashboardIndex: number) => {
  localStore.set(DASHBOARD_LOCAL_STORAGE_KEY, dashboardIndex);
};

const _initialDashboardIndex = (): number => {
  return localStore.get(DASHBOARD_LOCAL_STORAGE_KEY) ?? 0;
};

function getError(error: ProblemDetails) {
  if (error == null) {
    return null;
  }

  if (error.response?.status === 404) {
    return T.dashboard.error.nodashboardsfound;
  }

  return T.common.unknownerror;
}

const DashboardPage = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  const params = useParams<NodeParams>();
  const nodeId = params.nodeId;
  const nodeDashboardCollectionMap = useOperatorSelector(
    (state) => state.operator.nodeDashboardCollectionMap
  );
  const defaultDashboardCollection = useOperatorSelector(
    (state) => state.operator.defaultDashboardCollection
  );
  const { tenantId } = useContext(TenantContext);

  const navigate = useNavigate();
  const selectedDashboardIndex = _.parseInt(params.subPage ?? '0') ?? 0;
  const setSelectedDashboardIndex = useCallback(
    (newIndex: number) => {
      navigate(`/${tenantId}/home/${nodeId}/dashboard/${newIndex}`);
    },
    [navigate, nodeId, tenantId]
  );

  const setNode = useCallback(
    (newNodeId: string) => {
      navigate(
        `/${tenantId}/home/${newNodeId}/dashboard/${selectedDashboardIndex}`
      );
    },
    [navigate, selectedDashboardIndex, tenantId]
  );

  const urlBuilder = useCallback(
    (newTenantId: string, newNodeId: string, _equipmentId: string) => {
      return `/${newTenantId}/home/${newNodeId}/dashboard/${selectedDashboardIndex}`;
    },
    [selectedDashboardIndex]
  );

  const reduxDashboardDataValue = useDashboardDataFromRedux();
  const [dashboardDataValue, setDashboardDataValue] = useState({
    nodeId,
    setNode,
    ...reduxDashboardDataValue
  });
  const enums = useOperatorSelector((state) => state.general.enums);
  const signalTypesNameMap = useOperatorSelector(
    (state) => state.general.signalTypesNameMap
  );

  const [addToPersonalPanelId, setAddToPersonalPanelId] = useSearchParamState(
    'add-to-personal-panel-id',
    null
  );

  const setAddToPersonalPanel = useCallback(
    (panel: DashboardPanel) => {
      setAddToPersonalPanelId(panel?.id);
    },
    [setAddToPersonalPanelId]
  );

  const hideAddToPersonalDialog = useCallback(
    () => setAddToPersonalPanelId(null),
    [setAddToPersonalPanelId]
  );

  const [dashboardCollection, setDashboardCollection] =
    useState<DashboardCollectionViewResponseModel>(null);

  const lastCollectionWithoutMigrations = useRef(null);

  useEffect(() => {
    _updateLastDashboardIndex(selectedDashboardIndex);
  }, [selectedDashboardIndex]);

  const updateCollection = useCallback(
    (newCollectionWithoutMigrations: DashboardCollectionViewResponseModel) => {
      if (lastCollectionWithoutMigrations.current) {
        if (
          _.isEqual(
            lastCollectionWithoutMigrations.current,
            newCollectionWithoutMigrations
          )
        ) {
          return;
        }
      }

      lastCollectionWithoutMigrations.current = newCollectionWithoutMigrations;

      if (newCollectionWithoutMigrations != null) {
        const collection = applyDashboardMigrationsToCollection(
          newCollectionWithoutMigrations,
          enums,
          signalTypesNameMap
        );
        setDashboardCollection(collection);
        const numDashboards = collection.dashboards.length;
        if (selectedDashboardIndex >= numDashboards) {
          setSelectedDashboardIndex(numDashboards - 1);
        }
      } else {
        setDashboardCollection(null);
        setSelectedDashboardIndex(0);
      }
    },
    [
      enums,
      selectedDashboardIndex,
      setSelectedDashboardIndex,
      signalTypesNameMap
    ]
  );

  const getCollectionQuery =
    APIGen.Dashboard.getDashboardCollectionViewByNodeId.useQuery({
      nodeId
    });

  const error = getError(getCollectionQuery.error);

  const lastCollectionDataQuery = useRef(null);

  useEffect(() => {
    if (
      getCollectionQuery.data &&
      getCollectionQuery.data !== lastCollectionDataQuery.current
    ) {
      lastCollectionDataQuery.current = getCollectionQuery.data;
      setCachedDashboardCollection(getCollectionQuery.data);
      updateCollection(getCollectionQuery.data);
    }
  }, [getCollectionQuery.data, updateCollection]);

  useEffect(() => {
    const id = nodeId.startsWith(ROOT_NODE_ID) ? null : nodeId;
    const collectionId = nodeDashboardCollectionMap[id];

    if (collectionId == null || id == null) {
      updateCollection(defaultDashboardCollection);
    } else {
      const collection = getCachedDashboardCollection(collectionId);

      if (collection != null) {
        updateCollection(collection);
      } else {
        setDashboardCollection(null);
        setSelectedDashboardIndex(0);
      }
    }

    setDashboardDataValue((oldValue) => ({ ...oldValue, nodeId, setNode }));
  }, [
    nodeId,
    setNode,
    nodeDashboardCollectionMap,
    defaultDashboardCollection,
    updateCollection,
    setSelectedDashboardIndex
  ]);

  useEffect(() => {
    setDashboardDataValue((oldValue) => ({
      ...oldValue,
      ...reduxDashboardDataValue
    }));
  }, [reduxDashboardDataValue]);

  const selectOptions: OptionWithIcon<DashboardViewResponseModel>[] =
    useMemo(() => {
      return _(dashboardCollection?.dashboards)
        .map((dashboard) => ({
          label: dashboard.name,
          value: dashboard
        }))
        .orderBy(
          { value: { dashboardId: dashboardCollection?.defaultDashboardId } },
          ['desc']
        )
        .value();
    }, [dashboardCollection]);

  const onChangeDashboard = useCallback(
    (option: OptionWithIcon<DashboardViewResponseModel>) => {
      let index = _.indexOf(selectOptions, option);
      index = index === -1 ? 0 : index;
      setSelectedDashboardIndex(index);
      _updateLastDashboardIndex(index);
    },
    [selectOptions, setSelectedDashboardIndex]
  );

  const selectedOption =
    selectOptions[selectedDashboardIndex] ?? _.head(selectOptions);

  const [timeRange, timeRangeComponent] = useTimeRangeSelector();

  const toolbarItems = useMemo(() => {
    return (
      <>
        <ToolbarItem expanding>
          {dashboardCollection?.dashboards.length > 1 && (
            <CollapsingSegmentControlPicker
              options={selectOptions}
              value={selectedOption}
              onChangeValue={onChangeDashboard}
            />
          )}
        </ToolbarItem>
        <ToolbarItem className={styles.timerange}>
          {timeRangeComponent}
        </ToolbarItem>
      </>
    );
  }, [
    timeRangeComponent,
    onChangeDashboard,
    selectOptions,
    selectedOption,
    dashboardCollection?.dashboards
  ]);
  const selectedDashboard = selectOptions[selectedDashboardIndex]?.value;
  const dashboardData = selectedDashboard?.jsonData as DashboardFile;

  const { menuOptions, menuComponent } = useDashboardMenu({
    onAddToPersonal: setAddToPersonalPanel
  });

  const addToPersonalPanel = dashboardData?.panels?.find(
    (panel) => panel.id === addToPersonalPanelId
  );

  usePageTitleCallback({
    mainTitle: T.location.tabs.dashboard,
    subTitle: selectedDashboard?.name ?? '',
    onTitleChanged
  });

  return (
    <ToolbarContentPage
      title={T.location.tabs.dashboard}
      wrapContent={false}
      toolbarItems={toolbarItems}
      urlBuilder={urlBuilder}
      helpPath={HelpPaths.docs.operator.dashboard}
    >
      {getCollectionQuery.isLoading && selectedDashboard == null && (
        <LoadingContainer isLoading showSpinner />
      )}

      {error && <ErrorNotice>{error}</ErrorNotice>}

      {selectedDashboard && selectedDashboard.jsonData && !error && (
        <DashboardDataContext.Provider value={dashboardDataValue}>
          <TimeRangeContext.Provider value={timeRange}>
            <Dashboard
              responsive
              data={dashboardData}
              isResizable={false}
              isDraggable={false}
              menuOptions={menuOptions}
            />
            <DashboardCopyPanelToPersonalModal
              panel={addToPersonalPanel}
              isOpen={addToPersonalPanel != null}
              onModalClose={hideAddToPersonalDialog}
            />
          </TimeRangeContext.Provider>
        </DashboardDataContext.Provider>
      )}

      {!getCollectionQuery.isLoading &&
        !_.isNull(selectedDashboard) &&
        !selectedDashboard?.jsonData && (
          <NoDataMessage title={T.dashboard.missingdashboards} message={null} />
        )}

      {menuComponent}
    </ToolbarContentPage>
  );
};

export default React.memo(DashboardPage);
