import React, { useRef, useState, Dispatch, SetStateAction } from 'react';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import { SeriesIntervalToString } from 'js/modules/signalCollections/signalCollections';
import styles from './IntervalSelector.module.css';
import T from 'ecto-common/lib/lang/Language';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { components } from 'react-select';
import colors from 'ecto-common/lib/styles/variables/colors';
import { SeriesInterval } from 'ecto-common/lib/types/EctoCommonTypes';

type IntervalSelectorProps = {
  seriesInterval: SeriesInterval;
  setSeriesInterval: Dispatch<SetStateAction<SeriesInterval>>;
  autoUpdateEnabled: boolean;
  setAutoUpdateEnabled: Dispatch<SetStateAction<boolean>>;
  isClearable: boolean;
};

const IntervalSelector = ({
  seriesInterval,
  setSeriesInterval,
  autoUpdateEnabled,
  setAutoUpdateEnabled,
  isClearable
}: IntervalSelectorProps) => {
  const [showingSeriesSelector, setShowingSeriesSelector] = useState(false);

  // Nasty workaround for react-selects view life cycle notification being hard to work with.
  // If we actually did set a valid value (and we did not click in the header which has a null
  // value) we set this to true.

  // onInputChange then handles out of control clicks, clicks in the value container etc which
  // should close the component. We always get a notification here though for almost any kind
  // of event, including clicks in the group header. We utilize this state variable to check
  // for these kind of events so that we do not close the selector when this happens.

  const _didSetValue = useRef(false);

  const intervalOptions: GenericSelectOption<string>[] =
    SeriesIntervalToString().map(([value, localization]) => {
      return {
        label: localization,
        value
      };
    });

  const allOptions = [
    {
      label: (
        <div className={styles.selectControlHeader}>
          <Checkbox
            onChange={() => setAutoUpdateEnabled(!autoUpdateEnabled)}
            checked={autoUpdateEnabled}
          >
            {T.graphs.autoupdate}
          </Checkbox>
        </div>
      ),
      value: null as string
    } as GenericSelectOption<string>
  ].concat(intervalOptions);

  let selectedIntervalOption = intervalOptions.find(
    (x) => x.value === seriesInterval
  );
  let singleValueText: React.ReactNode = null;

  if (selectedIntervalOption === undefined) {
    selectedIntervalOption = null;
  } else {
    singleValueText = selectedIntervalOption.label;
  }

  let seriesPlaceholder = T.graphs.custominterval;

  if (autoUpdateEnabled) {
    seriesPlaceholder = T.format(
      T.graphs.autoupdateformat,
      seriesPlaceholder
    ).join('');
    if (singleValueText) {
      singleValueText = T.format(T.graphs.autoupdateformat, singleValueText);
    }
  }
  // When we should show a placeholder, we fake a value so we can get the 'clear' icon to work.
  if (seriesInterval === null) {
    singleValueText = seriesPlaceholder;
  }
  // eslint-disable-next-line react/prop-types
  const SingleValue = ({
    children: _children,
    ...props
  }: {
    children: React.ReactNode;
  }) => (
    // @ts-ignore-next-line
    <components.SingleValue {...props}>
      <label className={styles.label}>{T.graphs.zoom}</label> {singleValueText}
    </components.SingleValue>
  );

  // eslint-disable-next-line react/prop-types
  const Placeholder = ({
    children: _children,
    ...props
  }: {
    children: React.ReactNode;
  }) => (
    // @ts-ignore-next-line
    <components.Placeholder {...props}>
      <label className={styles.label}>{T.graphs.zoom}</label>{' '}
      {seriesPlaceholder}
    </components.Placeholder>
  );

  return (
    <Select
      additionalStyles={{
        option: (stylesParam, { data }) => {
          return {
            borderBottom:
              data.value === null && '1px solid ' + colors.surface4Color,
            ...stylesParam
          };
        }
      }}
      menuIsOpen={showingSeriesSelector}
      placeholder={seriesPlaceholder}
      isSearchable={false}
      isClearable={isClearable}
      className={styles.seriesSelectControl}
      options={allOptions}
      onBlur={() => {
        setShowingSeriesSelector(false);
      }}
      onMenuOpen={() => {
        setShowingSeriesSelector(true);
      }}
      onInputChange={(_e, data) => {
        if (data.action === 'set-value') {
          _didSetValue.current = true;
        } else if (data.action === 'menu-close') {
          if (_didSetValue.current) {
            _didSetValue.current = false;
          } else {
            setShowingSeriesSelector(false);
          }
        }
      }}
      onChange={(e: GenericSelectOption) => {
        if (e?.value) {
          setShowingSeriesSelector(false);
          setSeriesInterval(e.value as SeriesInterval);
        } else if (e == null) {
          setShowingSeriesSelector(false);
          setSeriesInterval(null);
        } else if (e.value == null) {
          // Clicked on auto update; just close the menu.
          setShowingSeriesSelector(false);
        }
      }}
      // Override selected option when we show fake placeholder, otherwise the first item will be styled selected.
      isOptionSelected={
        selectedIntervalOption ? null : (option) => option.value === -1
      }
      components={{ SingleValue, Placeholder }}
      // Add placeholder fake item to get the clear icon to work.
      value={selectedIntervalOption || { label: seriesPlaceholder, value: -1 }}
    />
  );
};

export default React.memo(IntervalSelector);
